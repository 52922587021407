// function to import all images

export const images = {};

function importAll(r, obj) {
  const target = obj;
  r.keys().forEach(key => {
    target[key] = r(key);
  });
}

// At build-time cache will be populated with all required images.
importAll(
  require.context('../images/', true, /^((?![\\/]symbols[\\/]).)*\.(jpg|jpeg|svg|png)$/),
  images,
);
