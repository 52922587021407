import React, { useEffect, useContext, useState } from 'react';
import { graphql, navigate } from 'gatsby';
// import PropTypes from 'prop-types';
import queryString from 'query-string';
import Cookies from 'js-cookie';
import { StateContext } from '../../utils/globalState';

import Layout from '../layout';
import SEO from '../seo';
import PayoffIndex from '../Payoff/index';

const getUrlToken = query => queryString.parse(query);

const Index = ({ pageContext, data, location }) => {
  const { journey, connectToken, playlist, updateState } = useContext(StateContext);
  const [currentCms, setCurrentCms] = useState(null);
  const { token } = getUrlToken(location.search);

  const currentJourney = Cookies.get('journey');
  const firstChoice = Cookies.get('first');
  const secondChoice = Cookies.get('second');
  const thirdChoice = Cookies.get('third');

  const isBrowser = typeof window !== 'undefined';

  /* Payoff Content for !<Top /> component */
  const regularPayoffContent = data.allPrismicPayoffContent.edges[0].node.data;

  // remove token from URL without redirecting
  if (token && !connectToken) {
    window.history.replaceState({}, document.title, `/${pageContext.territory}/your-playlist`);
    // check we don't already have a connect token to avoid rendering loop
    if (!connectToken) {
      updateState({
        connectToken: token,
        journey: currentJourney,
        first: firstChoice,
        second: secondChoice,
        third: thirdChoice,
      });
    }
  }

  const getContent = () => {
    const content = data.allPrismicPayoff.edges;

    for (let i = 0; i < content.length; i += 1) {
      const dataKey = content[i].node.data.key.text;

      if (dataKey === (journey || currentJourney)) {
        setCurrentCms(content[i].node.data);
      }
    }
  };

  useEffect(() => {
    getContent();

    // if we're in browser and we have no token and no playlist then redirect to start
    if (isBrowser && !token && !playlist) {
      navigate(`/${pageContext.territory}`);
    }
  }, []);

  return (
    <Layout>
      <SEO
        title="Visit California"
        pageUrl="/your-playlist"
        description="Visit California"
        twitterUsername="@VisitCA"
        lang={pageContext.territory}
      />
      <PayoffIndex
        cms={currentCms}
        lang={pageContext.territory}
        regularPayoffContent={regularPayoffContent}
      />
    </Layout>
  );
};

export const query = graphql`
  query($lang: String!) {
    allPrismicPayoff(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          data {
            key {
              text
            }
            payoff_text {
              text
            }
            payoff_title {
              text
            }
          }
        }
      }
    }
    allPrismicPayoffContent(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          data {
            icon_title {
              text
            }
            playlist_tab {
              text
            }
            tracklist_tab {
              text
            }
            playlist_text {
              text
            }
            playlist_title {
              text
            }
            search_button {
              text
            }
            search_text {
              text
            }
            search_title {
              text
            }
            slider_title {
              text
            }
          }
        }
      }
    }
  }
`;

export default Index;
