import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as ScrollMagic from 'scrollmagic';
import { Sine, TimelineMax } from 'gsap';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';

/* Background */
import mobile from '../../images/background/payoff/roadtrip_mobile.jpg';
import tablet from '../../images/background/payoff/roadtrip_tablet.jpg';
import desktop from '../../images/background/payoff/roadtrip_desktop.jpg';

const SearchWrapper = styled.div`
  height: 100vh;
  min-height: 560px;
  background: url(${mobile});
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    background: url(${tablet});
    background-size: cover;
    background-position: center;
    min-height: 1024px;
    justify-content: flex-start;
  }

  @media (min-width: 1024px) {
    min-height: 46.25rem;
    background: url(${desktop});
    background-size: cover;
    background-position: center;
  }
`;

const SearchContent = styled.div`
  text-align: center;
  color: #ffffff;
  transform: translateY(12%);

  @media (min-width: 768px) {
    max-width: 36.875rem;
    text-align: left;
    margin-left: 9.375vw;
  }

  @media (min-width: 1024px) {
    transform: translateY(10%);
  }
`;

const Title = styled.h2`
  text-transform: uppercase;
  font-family: 'Trend Slab';
  font-size: 26px;
  line-height: 34px;
  margin: 0px;

  @media (min-width: 768px) {
    font-size: 62px;
    line-height: 64px;
  }

  @media (min-width: 1024px) {
    font-size: 4.5rem;
    line-height: 4.5625rem;
  }
`;

const Paragraph = styled.p`
  font-family: 'Geometr415 Lt BT';
  font-size: 18px;
  line-height: 23px;
  padding: 0px 42px;
  margin: 12px 0px 30px;
  max-width: 450px;

  @media (min-width: 768px) {
    padding: 0px;
    margin: 0.8125rem 0px 36px;
    max-width: 29.375rem;
    line-height: 1.5625rem;
    font-size: 1.25rem;
  }

  @media (min-width: 1024px) {
    margin-bottom: 2rem;
    width: 24.375rem;
  }
`;

const Button = styled.button`
  text-transform: uppercase;
  height: 3.125rem;
  width: 16rem;
  border-radius: 0.5rem;
  background-color: #ffffff;
  color: #000000;
  font-family: 'Trend Sans';
  font-size: 0.875rem;
  line-height: 1.125rem;
  cursor: pointer;
  margin: 0px;
  padding: 0.1875rem 0px 0px;
  letter-spacing: -0.03125rem;
  border: 0px;
  transition: all 0.25s ease-in-out;

  :hover {
    background-color: #ffd100;
  }

  :focus {
    outline: none;
  }
`;

const Search = ({ cms }) => {
  useEffect(() => {
    /* Set Scroll Magic */
    ScrollMagicPluginGsap(ScrollMagic, Sine, TimelineMax);

    const controller = new ScrollMagic.Controller();
    const tl = new TimelineMax();

    tl.from('.animate', 0.6, {
      opacity: 0,
      ease: Sine.easeInOut,
    });

    const scene = new ScrollMagic.Scene({ triggerElement: '.stage' })
      .setTween(tl)
      .addTo(controller);
  }, []);

  return (
    <SearchWrapper className="stage">
      <SearchContent>
        <div className="animate">
          <Title dangerouslySetInnerHTML={{ __html: cms.search_title.text }} />
          <Paragraph>{cms.search_text.text}</Paragraph>
          <a
            href="https://www.visitcalifornia.com/road-trips"
            target="_blank"
            rel="noopener noreferrer"
            data-event-label="payoff search now button"
          >
            <Button>{cms.search_button.text}</Button>
          </a>
        </div>
      </SearchContent>
    </SearchWrapper>
  );
};

export default Search;
