import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import { StateContext } from '../../utils/globalState';
import RacApi from '../../utils/api';

/* Components */
import Loader from '../loader';
import Top from './top';
import Slider from './slider';
import Playlist from './playlist';
import Search from './search';
import Icons from './icons';
import Footer from '../footer';

const PayoffWrapper = styled.div`
  /* height: 100vh; */
  position: relative;
`;

const WhiteContainer = styled.div`
  background: #ffffff;
  text-align: center;
  position: relative;

  h1 {
    padding: 121px 0px 0px;
    text-transform: uppercase;
    color: #3d4643;
    font-family: 'Trend Slab';
    font-size: 28px;
    line-height: 34px;

    @media (min-width: 768px) {
      padding-top: 9.6875rem;
      font-size: 2.625rem;
      line-height: 3.25rem;
      margin-left: 9.375vw;
    }
  }

  @media (min-width: 768px) {
    text-align: left;
  }
`;

const PayoffIndex = ({ cms, lang, regularPayoffContent }) => {
  const { trips, playlist, first, second, third, updateState, connectToken, journey } = useContext(
    StateContext,
  );
  const [loader, setLoader] = useState(true);
  const [loaderTime, setLoaderTime] = useState(true);
  const cookiejourney = Cookies.get('journey');

  const generate = () => {
    let generatedPlaylist;
    let chosenTrips;

    const createPlaylist = new RacApi({
      url: 'api/playlists/generator',
      key: connectToken,
      method: 'post',
    });

    createPlaylist
      .submit({
        type: 'top-tracks',
        territory: lang,
        q1: first,
        q2: second,
        q3: third,
      })
      .then(response => {
        generatedPlaylist = response.playlist;
        chosenTrips = response.trip.personality;
        updateState({ playlist: generatedPlaylist, trips: chosenTrips });
      });
  };

  const actionScroll = () => {
    document
      .querySelector('.scroll-target')
      .scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
  };

  useEffect(() => {
    if (!playlist && connectToken) {
      generate();
    }
  }, [connectToken]);

  return (
    <PayoffWrapper>
      {loader && <Loader setLoader={setLoader} loaderTime={loaderTime} />}
      <Top actionScroll={actionScroll} cms={cms} lang={lang} />
      {regularPayoffContent && (
        <React.Fragment>
          <WhiteContainer className="scroll-target">
            <h1>{regularPayoffContent.slider_title.text}</h1>
            {playlist && (
              <React.Fragment>
                <Slider trips={trips} cookiejourney={cookiejourney} localJourney={journey} />
                <Playlist cms={regularPayoffContent} lang={lang} />
              </React.Fragment>
            )}
          </WhiteContainer>
          <Search cms={regularPayoffContent} />
          {playlist && <Icons cms={regularPayoffContent} lang={lang} playlist={playlist} />}
        </React.Fragment>
      )}
      <Footer />
    </PayoffWrapper>
  );
};

export default PayoffIndex;
