/* eslint-disable operator-linebreak */
/* eslint-disable react/button-has-type */
import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import Svg from 'react-inlinesvg';
import { TweenMax } from 'gsap';
import { StateContext } from '../../utils/globalState';

/* SVG */
import logo from '../../images/svgs/loader_logo.svg';
import play from '../../images/svgs/playhead_white.svg';
import spotify from '../../images/svgs/listen_on_spotify_white.svg';

const PlaylistWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .add_mexico_styles {
    @media (max-width: 1024px) {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const PlaylistContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  @media (min-width: 768px) {
    flex-direction: row;
    /* margin: 69px 0px 207px; */
    margin: 69px 0px 150px;
  }

  @media (min-width: 1024px) {
    margin: 0px 0px 10.8125rem;
  }
`;

const Logo = styled.a`
  height: 220px;
  width: 174px;
  filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.2));
  background: url(${logo});
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 56px 0px 33px;

  @media (min-width: 768px) {
    margin: 0px 83.5px 0px 0px;
    height: 292px;
    width: 230.43px;
    filter: drop-shadow(1.25rem 1.25rem 2.5rem rgba(0, 0, 0, 0.2));
  }

  @media (min-width: 1024px) {
    height: 27.375rem;
    width: 21.5625rem;
    margin-right: 8.4375rem;
    margin-left: -4.21875rem;
  }

  :hover {
    .play_icon {
      transform: scale(1.1);
      filter: drop-shadow(0 0.425rem 0.55rem rgba(0, 0, 0, 0.5));
    }
  }
`;

const PlayIcon = styled(Svg)`
  height: 49.61px;
  width: 43.28px;
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  transition: all 0.25s ease-in-out;

  @media (min-width: 768px) {
    height: 64px;
    width: 55.83px;
    filter: drop-shadow(0 0.125rem 0.25rem rgba(0, 0, 0, 0.5));
  }

  @media (min-width: 1024px) {
    height: 5.875rem;
    width: 5.125rem;
  }
`;

const Tabs = styled.div`
  margin-bottom: 103px;

  @media (min-width: 768px) {
    margin-bottom: 0px;
  }
`;

const TabHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    text-transform: uppercase;
    color: #3d4643;
    font-family: 'Trend Slab';
    font-size: 12px;
    line-height: 14px;
    text-decoration: underline;
    cursor: pointer;
    margin: 0px 2.5px;
    background: transparent;
    border: 0px;
    padding: 0px;

    :focus {
      outline: none;
    }

    :nth-child(2) {
      margin: 0px 17.5px;
      text-decoration: none;
      opacity: 0.2;
    }
  }

  @media (min-width: 768px) {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: -60px;

    button {
      font-size: 0.875rem;
      line-height: 1.125rem;

      :first-child {
        margin: 0px 30px 0px -30px;
      }

      :nth-child(2) {
        margin: 0px 30px;
      }
    }
  }

  @media (min-width: 1024px) {
    position: static;
    justify-content: flex-start;

    button {
      :first-child {
        margin: 0px 1.875rem 0px 0px;
      }

      :nth-child(2) {
        margin: 0px;
      }
    }
  }

  .playlist_i {
    max-width: 12.5rem;
  }
`;

const TabContent = styled.div`
  height: 100%;
  margin-top: 30px;
  width: 15.9375rem;

  @media (min-width: 768px) {
    margin-top: 24px;
    height: 17.8125rem;
  }
  @media (min-width: 1024px) {
    margin-top: 2.5rem;
  }
`;

const TabTitle = styled.h4`
  color: #3d4643;
  font-family: 'Trend Slab';
  font-size: 18px;
  line-height: 23px;

  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 24px;
    width: 280px;
  }

  @media (min-width: 1024px) {
    font-size: 1.75rem;
    line-height: 2.125rem;
    width: 27.375rem;
  }
`;

const Paragraph = styled.p`
  color: #3d4643;
  font-family: 'Geometr415 Lt BT';
  font-size: 16px;
  line-height: 22px;
  margin: 0.625rem 0px 0px;

  @media (min-width: 768px) {
    line-height: 19px;
    font-size: 16px;
    padding: 0px;
  }

  @media (min-width: 1024px) {
    line-height: 1.5625rem;
    font-size: 1.125rem;
    width: 24.8125rem;

    /* Stop stuff jumping on change */
    margin: 0.868rem 0;
  }
`;

const TabButton = styled.button`
  height: 3.125rem;
  width: 16rem;
  border-radius: 0.5rem;
  background: linear-gradient(270deg, #70c6bf 0%, #53b8d0 100%);
  margin: 1.875rem 0px 1.3125rem;
  padding: 0.1875rem 0px 0px;
  cursor: pointer;
  border: 0px;
  transition: all 0.2s ease-in-out;

  :focus {
    outline: none;
  }

  :hover {
    transform: scale(1.05);
    box-shadow: 0.5rem 0.5rem 0.9rem rgba(0, 0, 0, 0.2);
  }

  @media (min-width: 1024px) {
    padding: 0px;
  }
`;

const SLogo = styled(Svg)`
  height: 1.770625rem;
  width: 10.625rem;
  padding-top: 2px;
`;

const Duration = styled.h5`
  color: #3d4643;
  font-family: 'Trend Slab';
  font-size: 0.75rem;
  line-height: 0.875rem;
`;

const Tracks = styled.ul`
  height: 122px;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: left;

  @media (min-width: 768px) {
    min-width: 17.5rem;
    height: 10.625rem;
  }

  li {
    display: flex;
    margin-bottom: 0.4375rem;
  }
`;

const TrackInfo = styled.h3`
  width: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #3d4643;
  font-family: 'Geometr415 Lt BT';
  font-size: 0.875rem;
  line-height: 1.375rem;
  padding-right: 0.625rem;

  &.artist {
    font-size: 0.75rem;
    padding-right: 0.15625rem;
  }
`;

const Playlist = ({ cms, lang }) => {
  const [tab, setTab] = useState(1);
  const { playlist } = useContext(StateContext);
  const currentTracks = playlist.tracks;

  const changeTabs = () => {
    if (tab === 1) {
      setTab(2);
      TweenMax.to('.playlist_i', 0.3, { css: { textDecoration: 'none', opacity: 0.2 } });

      TweenMax.to('.tracklist', 0.3, { css: { textDecoration: 'underline', opacity: 1 } });
    } else {
      setTab(1);
      TweenMax.to('.tracklist', 0.3, { css: { textDecoration: 'none', opacity: 0.2 } });

      TweenMax.to('.playlist_i', 0.3, { css: { textDecoration: 'underline', opacity: 1 } });
    }
  };

  useEffect(() => {
    if (lang === 'es-mx' && cms) {
      document.querySelector('.content').classList.add('add_mexico_styles');
    }
  }, [cms]);

  return (
    <PlaylistWrapper>
      <PlaylistContent>
        <Logo
          href={playlist.url}
          target="_blank"
          rel="noopener noreferrer"
          data-event-label="logo listen on spotify"
        >
          <PlayIcon src={play} className="play_icon" />
        </Logo>
        <Tabs>
          <TabHeader>
            <button
              className="playlist_i"
              onClick={changeTabs}
              data-event-label="select playlist tab"
            >
              {cms.playlist_tab.text}
            </button>
            <button
              className="tracklist"
              onClick={changeTabs}
              data-event-label="select tracklist tab"
            >
              {cms.tracklist_tab.text}
            </button>
          </TabHeader>
          <TabContent className="content">
            {tab === 1 ? (
              <React.Fragment>
                <TabTitle>{cms.playlist_title.text}</TabTitle>
                <Paragraph>{cms.playlist_text.text}</Paragraph>
              </React.Fragment>
            ) : (
              <Tracks>
                {playlist &&
                  currentTracks.map(track => (
                    <li key={track.id}>
                      <TrackInfo>{track.name}</TrackInfo>
                      <TrackInfo className="artist">{track.artists[0].name}</TrackInfo>
                    </li>
                  ))}
              </Tracks>
            )}
            <a
              href={playlist.url}
              target="_blank"
              rel="noopener noreferrer"
              data-event-label="listen on spotify button"
            >
              <TabButton>
                <SLogo src={spotify} />
              </TabButton>
            </a>
            <Duration>{`${currentTracks.length} songs, ${playlist.duration}`}</Duration>
          </TabContent>
        </Tabs>
      </PlaylistContent>
    </PlaylistWrapper>
  );
};

export default Playlist;
