/* eslint-disable operator-linebreak */
import React, { useState } from 'react';
import styled from 'styled-components';
import Svg from 'react-inlinesvg';
import * as clipboard from 'clipboard-polyfill';

/* SVG */
import facebook from '../../images/svgs/social_facebook.svg';
import twitter from '../../images/svgs/social_twitter.svg';
import link from '../../images/svgs/social_link.svg';
import tick from '../../images/svgs/tick-white.svg';

const IconWrapper = styled.div`
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding-bottom: 130px;

  @media (min-width: 768px) {
    flex-direction: row;
    text-align: left;
    padding-bottom: 106px;
  }

  @media (min-width: 1024px) {
    padding-bottom: 2.5rem;
  }
`;

const Title = styled.div`
  text-transform: uppercase;
  font-family: 'Trend Slab';
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  padding: 0px 10px;
  margin: 0px 0px 1.875rem;

  @media (min-width: 768px) {
    font-size: 2rem;
    line-height: 2.375rem;
    width: 336px;
    margin-bottom: 0px;
    padding: 0px;
  }

  @media (min-width: 1024px) {
    width: 25.5rem;
  }
`;

const IconFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1024px) {
    margin-left: 9.625rem;
  }
`;

const IconContainer = styled.a`
  height: 59px;
  width: 59px;
  background-color: #ffffff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0px;
  transition: all 0.25s ease-in-out;

  :nth-child(2) {
    margin: 0px 38px;
  }

  :hover {
    transform: translateY(-0.625rem);
  }

  @media (min-width: 1024px) {
    height: 5.875rem;
    width: 5.875rem;

    :nth-child(2) {
      margin: 0px 3.75rem;
    }
  }
`;

const Icon = styled(Svg)`
  &.i_link {
    height: 18px;
    width: 18px;
  }

  &.i_facebook {
    height: 20px;
    width: 9.33px;
  }

  &.i_twitter {
    height: 18px;
    width: 22.16px;
  }

  &.i_tick {
    height: 20px;
    width: 20px;

    * {
      fill: rgba(61, 70, 67, 1);
    }
  }

  @media (min-width: 1024px) {
    &.i_link {
      height: 1.75rem;
      width: 1.75rem;
    }

    &.i_facebook {
      height: 2rem;
      width: 0.933125rem;
    }

    &.i_twitter {
      height: 1.625rem;
      width: 2.000625rem;
    }

    &.i_tick {
      height: 1.875rem;
      width: 2.1875rem;
    }
  }
`;

const Icons = ({ lang, cms, playlist }) => {
  const [copied, setCopied] = useState(false);

  const copyText = () => {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1500);

    clipboard.writeText(`https://californiaroadtrips.withspotify.com/${lang}`);
  };

  const shareText =
    'I got my road trip soundtrack and am ready to road trip through California. Discover the %23RoadTripRepublic for yourself at ';

  return (
    <IconWrapper>
      <Title>{cms.icon_title.text}</Title>
      <IconFlex>
        <IconContainer onClick={copyText} data-event-label="copy link icon">
          {!copied ? (
            <Icon src={link} className="i_link" />
          ) : (
            <Icon src={tick} className="i_tick" />
          )}
        </IconContainer>
        <IconContainer
          data-event-label="facebook link icon"
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.facebook.com/sharer/sharer.php?u=${playlist.url}`}
        >
          <Icon src={facebook} className="i_facebook" />
        </IconContainer>
        <IconContainer
          data-event-label="twitter link icon"
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.twitter.com/intent/tweet?url=${playlist.url}&text=${shareText}`}
        >
          <Icon src={twitter} className="i_twitter" />
        </IconContainer>
      </IconFlex>
    </IconWrapper>
  );
};

export default Icons;
