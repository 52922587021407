import React from 'react';
import styled from 'styled-components';
import Swiper from 'swiper/dist/js/swiper.min';
import Svg from 'react-inlinesvg';
import { images } from '../../utils/importAllImages';

/* SVG */
import arrow from '../../images/svgs/arrow_right.svg';
import mobilePattern from '../../images/svgs/payoff_shape_mobile.svg';
import tabPattern from '../../images/svgs/payoff_shape_desktop.svg';

const SliderWrapper = styled.div`
  width: 100%;
  min-height: 383px;
  position: relative;

  .swiper-slide {
    min-width: 260px;
    display: flex;
    justify-content: center;
  }

  @media (min-width: 768px) {
    /* min-height: 760px; */
    min-height: 44.6875rem;

    .swiper-slide {
      justify-content: flex-start;
      margin-left: 9.375vw;
      /* min-width: 420px; */
      min-width: 26.25rem;
    }
  }
`;

const SlideBox = styled.a`
  height: 210.48px;
  width: 260px;
  border-radius: 0.625rem;
  background-color: #ffffff;
  box-shadow: 0.625rem 0.625rem 1.25rem rgba(0, 0, 0, 0.2);
  margin: 1.875rem 0px;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.25s ease-in-out;
  border: 0px;

  :hover {
    transform: scale(1.05);
    box-shadow: 1rem 1rem 1.75rem rgba(0, 0, 0, 0.2);

    /* background: linear-gradient(270deg, #70c6bf 0%, #53b8d0 100%); */
  }

  img {
    width: 100%;
    height: 157.24px;
  }

  @media (min-width: 768px) {
    height: 21.25rem;
    width: 26.25rem;

    img {
      height: 15.875rem;
    }
  }
`;

const SlideText = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3d4643;
  font-family: 'Trend Slab';
  font-size: 12px;
  line-height: 14px;
  text-align: center;

  @media (min-width: 768px) {
    height: 5rem;
  }

  h4 {
    color: #3d4643;
    font-family: 'Trend Slab';
    font-size: 12px;
    line-height: 14px;
    width: 196px;

    @media (min-width: 768px) {
      font-size: 1.125rem;
      line-height: 1.4375rem;
      /* width: 21.25rem; */
      width: 20.25rem;
    }
  }
`;

const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  bottom: -25px;

  @media (min-width: 768px) {
    bottom: auto;
    right: 0;
    right: -16.875rem;
    top: -2.5625rem;
    z-index: 10;
  }
`;

const ArrowBox = styled.div`
  margin: 0px 1.5625rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &.left {
    transform: rotate(180deg) translateY(0.0375rem);
  }

  :focus {
    outline: none;
  }
`;

const Arrow = styled(Svg)`
  height: 1.193125rem;
  width: 1.806875rem;
  margin: 0px;

  * {
    fill: #ffffff;
  }

  @media (min-width: 768px) {
    * {
      fill: #3d4643;
    }
  }
`;

const PatternMobile = styled(Svg)`
  position: absolute;
  max-height: 340px;
  bottom: -18px;
  min-width: 140vw;
  height: auto;
  left: 0px;

  @media (min-width: 600px) {
    min-width: 100vw;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const PatternDesktop = styled(Svg)`
  display: none;
  max-height: 33rem;
  min-width: 100vw;
  height: auto;

  @media (min-width: 768px) {
    display: block;
    position: absolute;
    bottom: 60px;
    right: -185px;
  }

  @media (min-width: 1024px) {
    left: 0;
    bottom: 5.625rem;
  }
`;

class Slider extends React.Component {
  constructor(props) {
    super(props);

    this.state = { slides: [] };

    this.buildSlides = this.buildSlides.bind(this);
  }

  componentDidMount() {
    this.buildSlides();
  }

  buildSlides() {
    const { trips, cookiejourney, localJourney } = this.props;
    const slideElems = [];

    const currentJourney = cookiejourney || localJourney;

    for (let i = 0; i < trips.length; i += 1) {
      const element = (
        <div className="swiper-slide" key={i}>
          <SlideBox
            href={trips[i].url}
            target="_blank"
            rel="noopener noreferrer"
            data-event-label={`route: ${trips[i].slug}`}
          >
            <img src={images[`./routes/${currentJourney}/route_${i}.jpg`]} alt={`slider_${i}`} />
            <SlideText className="slide_text">
              <h4>{trips[i].name}</h4>
            </SlideText>
          </SlideBox>
        </div>
      );
      slideElems.push(element);
    }

    this.setState({ slides: slideElems }, this.initSlider);
  }

  initSlider() {
    this.swiper = new Swiper('.swiper-container', {
      loop: false,
      slidesPerView: 'auto',
      spaceBetween: -85,
      centeredSlides: false,
      breakpoints: {
        767: {
          centeredSlides: true,
          spaceBetween: 15,
        },
        1023: {
          spaceBetween: -40,
          centeredSlides: false,
        },
      },
      navigation: {
        nextEl: '.right',
        prevEl: '.left',
      },
    });
  }

  render() {
    const { slides } = this.state;

    return (
      <SliderWrapper className="slider">
        <div className="swiper-container">
          <div className="swiper-wrapper">{slides}</div>
          <ArrowContainer>
            <ArrowBox className="left" data-event-label="route prev slide">
              <Arrow src={arrow} />
            </ArrowBox>
            <ArrowBox className="right" data-event-label="route next slide">
              <Arrow src={arrow} />
            </ArrowBox>
          </ArrowContainer>
        </div>
        <PatternMobile src={mobilePattern} />
        <PatternDesktop src={tabPattern} />
      </SliderWrapper>
    );
  }
}

export default Slider;
