/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import Svg from 'react-inlinesvg';
import { StateContext } from '../../utils/globalState';
import { images } from '../../utils/importAllImages';

/* SVG */
import downArrow from '../../images/svgs/down_arrow_payoff.svg';

/* Components */
import Header from '../header';

const TopWrapper = styled.div`
  height: 100vh;
  width: 100%;
  min-height: 565px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (min-width: 768px) {
    min-height: 1024px;
  }

  @media (min-width: 1024px) {
    min-height: 46.25rem;
  }

  .add_mexico_styles {
    @media (min-width: 768px) {
      width: 46.6875rem;
    }

    @media (min-width: 1024px) {
      width: 70.6875rem;
    }
  }
`;

const InnerTop = styled.div`
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: translateY(-17%);

  @media (min-width: 768px) {
    transform: translateY(-25.5%);
  }

  @media (min-width: 768px) {
    transform: translateY(-19%);
  }
`;

const Title = styled.h1`
  font-family: 'Trend Slab';
  font-size: 28px;
  line-height: 34px;
  text-shadow: 5px 0 10px rgba(0, 0, 0, 0.2);
  margin: 80px 0px 20px;

  @media (min-width: 768px) {
    margin: 252px 0px 18px;
    line-height: 4.5rem;
    font-size: 4.375rem;
    text-shadow: 0.3125rem 0 1.25rem rgba(0, 0, 0, 0.2);
  }

  @media (min-width: 768px) {
    /* width: 56.6875rem; */
    width: 57.6875rem;
    margin: 6.875rem 0px 1.25rem;
  }
`;

const Divider = styled.div`
  height: 0.1875rem;
  width: 100px;
  border-radius: 0.09375rem;
  background-color: #ffd100;

  @media (min-width: 768px) {
    width: 12.5rem;
  }
`;

const Paragraph = styled.p`
  margin: 23px 0px 0px;
  font-family: 'Geometr415 Lt BT';
  font-size: 18px;
  line-height: 23px;
  padding: 0px 16px;
  max-width: 550px;

  @media (min-width: 768px) {
    padding: 0px;
    margin: 1.625rem 0px 0px;
    line-height: 1.5625rem;
    font-size: 1.25rem;
    width: 26.25rem;
    max-width: none;
  }
`;

const ArrowContainer = styled.div`
  position: absolute;
  bottom: 0;
  height: 4.1875rem;
  width: 2.3125rem;
  border-radius: 6.25rem 6.25rem 0 0;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0.1875rem solid #ffffff;
  border-bottom: 0px;
  transition: border 0.25s ease-in-out;

  :hover {
    border: 0.1875rem solid #ffd100;
    border-bottom: 0px;
  }
`;

const Arrow = styled(Svg)`
  margin: 7px 0px 0px;
  width: 1.25rem;
  height: 0.6875rem;
`;

const Top = ({ actionScroll, cms, lang }) => {
  const { journey } = useContext(StateContext);
  const [device, setDevice] = useState(null);

  const findDevice = () => {
    let currentDevice;

    if (window.innerWidth < 768) {
      // mobile
      currentDevice = 'mobile';
    } else if (window.innerWidth < 1024) {
      // tablet
      currentDevice = 'tablet';
    } else {
      // desktop
      currentDevice = 'desktop';
    }

    if (device !== currentDevice) {
      setDevice(currentDevice);
    }
  };

  useEffect(() => {
    /* Device Function */
    findDevice();
    window.addEventListener('resize', findDevice);
    return () => window.removeEventListener('resize', findDevice);
  }, [device]);

  useEffect(() => {
    if (lang === 'es-mx' && cms) {
      document.querySelector('.add_styles').classList.add('add_mexico_styles');
    }
  }, [cms]);

  return (
    <TopWrapper
      style={{
        backgroundImage: `url(${images[`./background/payoff/${journey}_${device}.jpg`]})`,
        backgroundSize: 'cover',
      }}
    >
      <InnerTop>
        <Header />
        {cms && (
          <React.Fragment>
            <Title className="add_styles">{cms.payoff_title.text}</Title>
            <Divider />
            <Paragraph>{cms.payoff_text.text}</Paragraph>
          </React.Fragment>
        )}
      </InnerTop>
      <ArrowContainer onClick={actionScroll} data-event-label="payoff scroll arrow">
        <Arrow src={downArrow} />
      </ArrowContainer>
    </TopWrapper>
  );
};

export default Top;
